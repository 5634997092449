import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.BASE;

const PublicacionService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/comunicaciones-cultura/portalColaborador/publicaciones");
		return respuesta.data;
	},
	// async actualizarContadorHearts(id) {
	// 	const respuesta = await HttpService(URL_API).get(`/comunicaciones-cultura/actualizarContadorHearts/${id}`);
	// 	return respuesta.data;
	// },
	async actualizarContadorHearts(request) {
		const respuesta = await HttpService(URL_API).post("/comunicaciones-cultura/publicacion/actualizarContadorHearts", request);
		return respuesta.data;
	},
	async actualizarContadorLikes(request) {
		const respuesta = await HttpService(URL_API).post("/comunicaciones-cultura/publicacion/actualizarContadorLikes", request);
		return respuesta.data;
	},
	async listarPaginado(params) {
		const respuesta = await HttpService(URL_API).get(`/comunicaciones-cultura/portalColaborador/publicaciones/paginado`, {
		params: params,
		});
		return respuesta.data;
	},
	async listarCumpleanios() {
		const respuesta = await HttpService(URL_API).get("/comunicaciones-cultura/portalColaborador/cumpleanios");
		return respuesta.data;
	},

	async listarCumpleaniosPaginado(params) {
		const respuesta = await HttpService(URL_API).get(`/comunicaciones-cultura/portalColaborador/cumpleanios/paginado`, {
		params: params,
		});
		return respuesta.data;
	},

	async listarCumpleaniosPaginadoHoy(params) {
		const respuesta = await HttpService(URL_API).get(`/comunicaciones-cultura/portalColaborador/cumpleaniosHoy/paginado`, {
		params: params,
		});
		return respuesta.data;
	},

	async listarReconocimientosPaginado(params) {
		const respuesta = await HttpService(URL_API).get(`/comunicaciones-cultura/portalColaborador/reconocimientos/paginado`, {
		params: params,
		});
		return respuesta.data;
	},
	async listarPublicacionesFijas() {
		const respuesta = await HttpService(URL_API).get("/comunicaciones-cultura/portalColaborador/publicacionesFijas");
		return respuesta.data;
	},
};
export default PublicacionService;
