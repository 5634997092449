import { HttpService } from "./HttpService";
import { URL } from "enum";
import qs from "qs";

const URL_API = URL.VACACIONES;

const VacacionesService = {
	async getDetalleVacacionesDelColaborador() {
		const respuesta = await HttpService(URL_API).get("/control-vacacional/colaborador");
		return respuesta.data;
	},
	async simularDescontarDias(body) {
		const respuesta = await HttpService(URL_API).post("/control-vacacional/simular-descontar-dias", body);
		return respuesta.data;
	},
};

const SolicitudAusenciaService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/solicitud");
		return respuesta.data;
	},

	async crear(body) {
		const respuesta = await HttpService(URL_API).post("/solicitud", body);
		return respuesta.data;
	},

	async listarPorJefeInmediato(params) {
		const respuesta = await HttpService(URL_API).get("/solicitud/jefeinmediato", {
			params: params,
		});
		return respuesta.data;
	},

	async aprobarPorJefeInmediato(body) {
		const respuesta = await HttpService(URL_API).post("/solicitud/jefeinmediato/aprobar", body);
		return respuesta.data;
	},

	async rechazarPorJefeInmediato(body) {
		const respuesta = await HttpService(URL_API).post("/solicitud/jefeinmediato/rechazar", body);
		return respuesta.data;
	},
	async listarHistoricoPorJefeInmediato() {
		const respuesta = await HttpService(URL_API).get("/solicitud/jefeinmediato/listarHistoricoSolicitudesAusencia");
		return respuesta.data;
	},
	async obtenerUrl(empresaId, solicitudId, plataforma) {
		const respuesta = await HttpService(URL_API).get(
			`/solicitud/jefeinmediato/solicitudId/${solicitudId}/empresa/${empresaId}/plataforma/${plataforma}/url`
		);
		return respuesta.data;
	},
	async obtenerDatosSolicitudAusencia(params) {
		const respuesta = await HttpService(URL_API).get("/solicitud/obtener-datos-solicitud-ausencia", {
			params: params,
		});
		return respuesta.data;
	},

	async verDocumentoAdjunto(body) {
		const respuesta = await HttpService(URL_API).post("/solicitud/jefeinmediato/verDocumentoAdjunto", body);
		return respuesta.data;
	},
	async cancelarSolicitud(body) {
		const respuesta = await HttpService(URL_API).post("/solicitud/cancelar", body);
		return respuesta.data;
	},
};

const TiposAusenciaService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/tipos");
		return respuesta.data;
	},
	async buscarPorId(tipoAusenciaId) {
		const respuesta = await HttpService(URL_API).get("/tipos/" + tipoAusenciaId);
		return respuesta.data;
	},
};

const JefeInmediatoService = {
	async listarColaboradores(params) {
		const respuesta = await HttpService(URL_API).get("/jefeinmediato/equipo", {
			params: params,
		});
		return respuesta.data;
	},
	async exportarSaldoReporteExcel(params) {
		const respuesta = await HttpService(URL_API).get("/jefeinmediato/exportarSaldoReporteExcel", {
			params: params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},

			responseType: "blob",
		});
		return respuesta;
	},
	 async programarVacacionesFromColaborador(body) {
		const respuesta = await HttpService(URL_API).post("/jefeinmediato/programarVacacionesFromPortalColaborador", body);
		return respuesta.data;
	},
	 async listarProgramacionVacacionesPorEquipo(params) {
		const respuesta = await HttpService(URL_API).get("/jefeinmediato/programacionVacaciones/equipo", {
			params: params,
		});
		return respuesta.data;
	},
};

export { VacacionesService, SolicitudAusenciaService, TiposAusenciaService, JefeInmediatoService };
