import { atom } from "jotai";

export const formularioSeleccionadoAtom = atom(undefined);
export const visibleVisorFormularioAtom = atom(false);
export const erroresFormularioAtom = atom({});
export const signatureModalVisibleAtom = atom(false);
export const signaturesMetadataAtom = atom([]);
export const componentSelectedAtom = atom(undefined);
export const zoomVisorFormularioAtom = atom(0.75);
export const documentoSeleccionadoAtom = atom(undefined);
export const bbAtom = atom(undefined);
export const visibilidadComponentesAtom = atom({});