import React, { useEffect, useContext } from 'react';
import { useState } from "react";
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import { useAtom } from 'jotai';
import { PortalColaboradorService } from 'services/PortalColaboradorService';
import { SecurityContext } from "../../../../../context/SecurityContextProvider";
import { datosPerfilAtom } from 'storage/MiPortalStorage';
import { UserOutlined } from "@ant-design/icons"
import { FcAlarmClock, FcConferenceCall, FcFinePrint, FcHighPriority, FcTodoList } from "react-icons/fc";
import { Boton } from './componentes/common';
import { ListaPublicaciones } from './componentes/ListaPublicaciones';
import { ListaCumpleaniosHoy } from './componentes/Cumpleanios';
import { MenuIzquierda } from './MenuIzquierda';
import { Provider as JotaiProvider } from "jotai";
import { ListaReconocimientos } from './componentes/Reconocimientos';
import { ListarBeneficiosExternos } from './componentes/BeneficiosExternos';

const MiPortalPageWeb = () => {
    return (
        // <JotaiProvider>
            <Container>
            {/* <SeccionIzquierda> */}
                {/* <Perfil /> */}
                {/* <Acciones /> */}
            {/* </SeccionIzquierda> */}

            <ContainerInformacion>
                {/* Sección Noticias / Información */}
                <ListaPublicaciones></ListaPublicaciones>
            </ContainerInformacion>
            <ContainerInformacionColumnaIDerecha>
                <ListaCumpleaniosHoy></ListaCumpleaniosHoy>
                <ListaReconocimientos></ListaReconocimientos>
                <ListarBeneficiosExternos></ListarBeneficiosExternos>
            </ContainerInformacionColumnaIDerecha>
        </Container>
        // </JotaiProvider>
    );
};

const Acciones = () => {
    const { getVersionApiColaborador, getUsuarioAutenticado, setEmpresaSeleccionada } = useContext(SecurityContext);
    const [opciones, setOpciones] = useState(null);

    useEffect(() => {
		const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;
		setOpciones(opcionesEmpresaSeleccionada);
		//setUrlImagenLogotipo(getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo);
    }, []);
    
    return (
        <>
            <div style={{ marginTop: "0px" }}>
                <MenuIzquierda opciones={opciones}>
                </MenuIzquierda>
            </div>
            
        {/* <div style={{ marginTop: "10px" }}>
            <Boton
                icono={<FcHighPriority size="1.5em" />}
                nombre="Documentos Pendientes"
            />

            <Boton
                icono={<FcFinePrint size="1.5em" />}
                nombre="Mis Documentos"
            />

            <Boton
                icono={<FcAlarmClock size="1.5em" />}
                nombre="Ausencias y Vacaciones"
            />

            <Boton
                icono={<FcTodoList size="1.5em" />}
                nombre="Bandeja de Tareas"
            />

            <Boton
                icono={<FcConferenceCall size="1.5em" />}
                nombre="Solicitud de Personal"
            /> 
        </div>  */}
        </>
    )
}

const Perfil = () => {
    const [datosPerfil, setDatosPerfil] = useAtom(datosPerfilAtom);

    useEffect(() => {
        const cargarDatosIniciales = () => {
            PortalColaboradorService.obtenerDatosPerfil()
                .then(response => {
                    setDatosPerfil(response);
                })
                .catch(error => {
                    console.error("Error al obtener la foto", error)
                })
        }
        cargarDatosIniciales();
    }, []);

    const FotoPersonal = () => {
        if (datosPerfil?.urlFoto) {
            return <Avatar size={64} src={datosPerfil.urlFoto} />
        }

        return (
            <Avatar size={64}
                style={{
                    backgroundColor: '#87d068',
                }}
                icon={<UserOutlined />}
            />
        )
    }

    const SeccionNombre = styled.span`
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #1678C1;
    `;

    const SeccionCargo = styled.span`
        margin-top: 5px;
        font-size: 12px;
        color: #878c8f;
    `;

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;
        background: white;
        border-radius: 5px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
    `

    return (
        <Container>
            <FotoPersonal />
            <SeccionNombre>
                {datosPerfil?.nombreCompleto}
            </SeccionNombre>
            <SeccionCargo>
                {datosPerfil?.cargo}
            </SeccionCargo>
        </Container>
    )

}

const Container = styled.div`
    width: 100%;
    display: flex;
`

const SeccionIzquierda = styled.div`
//     position: sticky;
//   top: 0;
//   height: 100vh;
  width: 25%;
//   z-index: 1;
//   background-color: #f0f0f0;
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   overflow-y: auto; /* Add this to handle overflow if needed */
`
const ContainerInformacion = styled.div`
    width: 70%;
    margin-left: 10px;
    
`
const ContainerInformacionColumnaIDerecha = styled.div`
    width: 30%;
    margin-left: 20px;
`
export default MiPortalPageWeb;