import React, { useRef, useEffect, useState } from "react";
import { Tag, Spin, Carousel, Modal, Button } from "antd";
import ReactQuill from "react-quill";
import moment from "moment";
import LoginService from "services/LoginService";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

// export const PublicacionContenido = ({ item }) => {
//   const [loading, setLoading] = useState(true);
//   const [activeImageIndex, setActiveImageIndex] = useState(0);
//   const [isCarouselVisible, setIsCarouselVisible] = useState(false);
//   const carouselRef = useRef(null);

//   useEffect(() => {
//     setLoading(false);
//   }, [item.imagenes]);

//   const renderImageGrid = () => {
//     const baseStyle = {
//       width: "100%",
//       height: "100%",
//       objectFit: "cover",
//       borderRadius: "4px",
//       cursor: "pointer",
//     };

//     return (
//       <div
//         style={{
//           display: "grid",
//           gridTemplateColumns: item.imagenes.length > 1 ? "1fr 1fr" : "1fr",
//           gap: "8px",
//         }}
//       >
//         {item.imagenes.map((img, index) => (
//           <img
//             key={index}
//             src={img}
//             alt={`imagen-${index}`}
//             style={baseStyle}
//             onClick={(e) => {
//               e.stopPropagation();
//               setActiveImageIndex(index);
//               setIsCarouselVisible(true);
//             }}
//           />
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div>
//       <div style={{ display: "flex", alignItems: "flex-start" }}>
//         <img
//           src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
//           alt="Logotipo de la empresa"
//           style={{ height: "32px", marginRight: "16px" }}
//         />
//         <div style={{ flex: 1 }}>
//           <h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
//           <p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
//             {moment(item.usuarioRegistro.fechaRegistro)
//               .locale("es")
//               .format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
//           </p>
//         </div>
//         <Tag color={item.colorEtiqueta}>{item.etiqueta}</Tag>
//       </div>

//       <ReactQuill
//         value={item.mensajeHTML}
//         readOnly={true}
//         className="custom-react-quill"
//         style={{ backgroundColor: "transparent" }}
//         theme="bubble"
//         modules={{ toolbar: false }}
//         placeholder="Escribe algo..."
//       />

//       <div style={{ marginTop: "16px" }}>{loading ? <Spin /> : renderImageGrid()}</div>

//       {isCarouselVisible && (
//         <Modal
//           open={isCarouselVisible}
//           footer={null}
//           onCancel={() => setIsCarouselVisible(false)}
//           centered
//           width="80vw"
//           //height="calc(100vh - 80px)"
//           closeIcon={<span style={{ fontSize: "20px", fontWeight: "bold" }}>×</span>}
//         >
//           <div style={{ position: "relative" }}>
//             <Button
//               shape="circle"
//               icon={<LeftOutlined />}
//               size="large"
//               onClick={() => carouselRef.current.next()}
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "10px",
//                 transform: "translateY(-50%)",
//                 backgroundColor: "rgba(255, 255, 255, 0.9)",
//                 border: "none",
//                 boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
//                 zIndex: 10,
//               }}
//             />

//             <Button
//               shape="circle"
//               icon={<RightOutlined />}
//               size="large"
//               onClick={() => carouselRef.current.next()}
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 right: "10px",
//                 transform: "translateY(-50%)",
//                 backgroundColor: "rgba(255, 255, 255, 0.9)",
//                 border: "none",
//                 boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
//                 zIndex: 10,
//               }}
//             />
//             <Carousel
//               ref={carouselRef}
//               initialSlide={activeImageIndex}
//               dots={true}
//               infinite={true}
//               swipeToSlide={true}
//             >
//               {item.imagenes.map((img, index) => (
//                 <div key={index} style={{ textAlign: "center" }}>
//                   <img
//                     src={img}
//                     alt={`carousel-img-${index}`}
//                     style={{
//                       width: "100%",
//                       height: "auto",
//                       maxHeight: "500px",
//                       objectFit: "contain",
//                       margin: "0 auto",
//                     }}
//                   />
//                 </div>
//               ))}
//             </Carousel>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default PublicacionContenido;

export const PublicacionContenido = ({ item }) => {
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(
    new Array(item.imagenes.length).fill(true) // Estado para cada imagen
  );
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    setLoading(false);
  }, [item.imagenes]);

  const handleImageLoad = (index) => {
    setImageLoading((prev) => {
      const newState = [...prev];
      newState[index] = false; // Marcar la imagen como cargada
      return newState;
    });
  };

  const renderImageGrid = () => {
    const baseStyle = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: item.imagenes.length > 1 ? "1fr 1fr" : "1fr",
          gap: "8px",
        }}
      >
        {item.imagenes.map((img, index) => (
          <div key={index} style={{ position: "relative", minHeight: "150px" }}>
            {imageLoading[index] && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <Spin />
              </div>
            )}
            <img
              src={img}
              alt={`imagen-${index}`}
              style={{
                ...baseStyle,
                display: imageLoading[index] ? "none" : "block", // Oculta la imagen hasta que se cargue
              }}
              onLoad={() => handleImageLoad(index)}
              onClick={(e) => {
                e.stopPropagation();
                setActiveImageIndex(index);
                setIsCarouselVisible(true);
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <img
          src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
          alt="Logotipo de la empresa"
          style={{ height: "32px", marginRight: "16px" }}
        />
        <div style={{ flex: 1 }}>
          <h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
          <p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
            {moment(item.usuarioRegistro.fechaRegistro)
              .locale("es")
              .format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
          </p>
        </div>
        <Tag color={item.colorEtiqueta}>{item.etiqueta}</Tag>
      </div>

      <ReactQuill
        value={item.mensajeHTML}
        readOnly={true}
        className="custom-react-quill"
        style={{ backgroundColor: "transparent" }}
        theme="bubble"
        modules={{ toolbar: false }}
        placeholder="Escribe algo..."
      />

      <div style={{ marginTop: "16px" }}>{loading ? <Spin /> : renderImageGrid()}</div>

      {isCarouselVisible && (
        <Modal
          open={isCarouselVisible}
          footer={null}
          onCancel={() => setIsCarouselVisible(false)}
          centered
          width="80vw"
          closeIcon={<span style={{ fontSize: "20px", fontWeight: "bold" }}>×</span>}
        >
          <div style={{ position: "relative" }}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              size="large"
              onClick={() => carouselRef.current.prev()} // Fix: Usar "prev()" en la flecha izquierda
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                border: "none",
                boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                zIndex: 10,
              }}
            />

            <Button
              shape="circle"
              icon={<RightOutlined />}
              size="large"
              onClick={() => carouselRef.current.next()}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                border: "none",
                boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                zIndex: 10,
              }}
            />
            <Carousel
              ref={carouselRef}
              initialSlide={activeImageIndex}
              dots={true}
              infinite={true}
              swipeToSlide={true}
            >
              {item.imagenes.map((img, index) => (
                <div key={index} style={{ textAlign: "center", position: "relative", minHeight: "500px" }}>
                  {imageLoading[index] && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                  <img
                    src={img}
                    alt={`carousel-img-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "contain",
                      margin: "0 auto",
                      display: imageLoading[index] ? "none" : "block", // Oculta la imagen hasta que se cargue
                    }}
                    onLoad={() => handleImageLoad(index)}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PublicacionContenido;