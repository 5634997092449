import React, { useEffect, useState, useRef } from "react";
import { Card, Avatar, Typography, List, Carousel, Tag, Button, Space, Image, Tooltip, Tabs, Empty, Menu } from "antd";
import { Spin, Modal } from "antd";
import {
	HeartOutlined,
	LikeOutlined,
	UserOutlined,
	LeftOutlined,
	RightOutlined,
	SelectOutlined,
	GiftOutlined,
	TrophyOutlined,
	SmileOutlined,
} from "@ant-design/icons";
import { FcRating, FcPlanner, FcReading } from "react-icons/fc";
import moment from "moment";
import "moment/locale/es";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { usePublicacion } from "../../componentes/web/hooks";
import { useAtom } from "jotai";
import LoginService from "services/LoginService";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { PublicacionService } from "services/comunicacion-cultura";
import { BeneficioExternoService } from "services/comunicacion-cultura";
import { publicacionesFijasAtom } from "../web/store/PublicacionStore";
// import { PublicacionItem } from "../web/componentes/ListaPublicaciones/componentes/PublicacionItem";
const { Meta } = Card;
const { Text, Link, Ellipsis } = Typography;
// const { Ellipsis } = Typography;
const MiPortalMovil = () => {
	return (
		<Container>
			<SeccionPortal>
				<Perfil />
				{/* <Cumpleanios /> */}
				<Celebraciones />
				<Comunicaciones />
			</SeccionPortal>
		</Container>
	);
};

const Perfil = () => {
	const [informacion, setInformacion] = useState(undefined);
	useEffect(() => {
		const cargarInformacion = async () => {
			const response = await PortalColaboradorService.buscarColaborador();
			setInformacion(response);
		};

		cargarInformacion();
	}, []);
	return (
		<Card
			bodyStyle={{ padding: 12 }} // Elimina el padding del body
			style={{
				borderRadius: "8px",
			}}
		>
			<Meta
				avatar={
					informacion?.urlFotoPersonalFirmado !== null ? (
						<Image src={informacion?.urlFotoPersonalFirmado} width={60} style={{ borderRadius: "50%" }} />
					) : (
						<Avatar size={60} icon={<UserOutlined />} />
					)
				}
				title={
					<div>
						<Text style={{ fontSize: "14px", color: "#666" }}>Hola,</Text>
						<br />
						<Text style={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}>
							{LoginService.getUsuarioAutenticado().nombreCompleto}
						</Text>
					</div>
				}
			/>
		</Card>
	);
};

const { TabPane } = Tabs;
const Celebraciones = () => {
	const [activeTab, setActiveTab] = useState("1");

	return (
		<div style={{ maxWidth: "100%", overflowX: "hidden" }}>
			<Card
				title="Celebraciones y Beneficios"
				bodyStyle={{ padding: 10 }} // Elimina el padding del body
				style={{
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}
			>
				{/* Contenedor para hacer scroll en móviles */}
				<div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
					<Tabs
						activeKey={activeTab}
						onChange={(key) => setActiveTab(key)}
						tabBarGutter={6} // Reduce espacio en móviles
						style={{ width: "100%" }}
					>
						<TabPane
							tab={
								<span>
									<FcPlanner size="1.5em" style={{ marginRight: "1px" }} />
									<span style={{ fontSize: "14px" }}>Cumpleaños</span>
								</span>
							}
							key="1"
						>
							<Cumpleanios />
						</TabPane>

						<TabPane
							tab={
								<span>
									<FcRating size="1.5em" style={{ marginRight: "1px" }} />
									<span style={{ fontSize: "14px" }}>Reconocimientos</span>
								</span>
							}
							key="2"
						>
							{/* <Empty description="No hay reconocimientos" /> */}
							<Reconocimientos />
						</TabPane>

						<TabPane
							tab={
								<span>
									<FcReading size="1.5em" style={{ marginRight: "1px" }} />
									<span style={{ fontSize: "14px" }}>Beneficios Externos</span>
								</span>
							}
							key="3"
						>
							<BeneficiosExternos />
						</TabPane>
					</Tabs>
				</div>
			</Card>
		</div>
	);
};

const BeneficiosExternos = () => {
	moment.locale("es");

	const [beneficiosExternos, setBeneficiosExternos] = useState([]);

	useEffect(() => {
		const getBeneficiosExternos = async () => {
			const respuesta = await BeneficioExternoService.listar();
			setBeneficiosExternos(respuesta);
		};

		getBeneficiosExternos();
	}, []);

	return (
		<div style={{ padding: "0px" }}>
			<Card
				style={{
					marginBottom: "0px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}
				bodyStyle={{
					padding: "0", // Sin padding dentro del Card
				}}
			>
				<Menu
					mode="vertical"
					onClick={(e) => {
						const beneficio = beneficiosExternos.find((b) => b.id.toString() === e.key);
						if (beneficio && beneficio.url) {
							window.open(beneficio.url, "_blank");
						}
					}}
					style={{
						height: "100%", // Ocupa todo el Card
						border: "none",
					}}
				>
					{beneficiosExternos.map((beneficio) => (
						<StyledMenuItem
							key={beneficio.id}
						>
							{beneficio?.logo && (
								<img
									src={beneficio.logo}
									alt="Logo Beneficio"
									style={{
									// height: "35px",
									width: "35px", // 🔹 Hacer que sea cuadrada antes de aplicar border-radius
									borderRadius: "25%", // 🔹 Hace la imagen circular
									marginRight: "8px",
									objectFit: "cover", // 🔹 Evita deformaciones si la imagen no es cuadrada
									border: "2px solid #ddd" // 🔹 (Opcional) Agrega un borde suave
									}}
								/>
							)}
							{beneficio.nombre}
						</StyledMenuItem>
					))}
				</Menu>
			</Card>
		</div>
	);
};

const Cumpleanios = () => {
	moment.locale("es");
	const history = useHistory();

	const [cumpleanios, setCumpleanios] = useState([]);
	const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);

	useEffect(() => {
		const getCumpleanios = async () => {
			const respuesta = await PublicacionService.listarCumpleaniosPaginadoHoy({
				pagina: pagina,
				cantidad: 3,
			});
			console.log("Cumpleanios::", respuesta);
			setCumpleanios(respuesta.cumpleanios);
			setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
		};

		getCumpleanios();
	}, [pagina]);

	const handleNext = () => {
		if (pagina < totalPaginas - 1) {
			setPagina((prev) => prev + 1);
		}
	};

	const handlePrev = () => {
		if (pagina > 0) {
			setPagina((prev) => prev - 1);
		}
	};

	// const BirthdayCard = ({ id, nombreYApellidoPaterno, fechaNacimiento, urlFotoPersonal }) => (
	// 	<div
	// 		style={{
	// 			display: "flex",
	// 			flexDirection: "column",
	// 			alignItems: "center",
	// 		}}
	// 	>
	// 		{/* Imagen de perfil con fondo de color */}
	// 		<Tooltip title={nombreYApellidoPaterno} key={id}>
	// 			{/* <div style={{ backgroundColor: "rgb(144 137 137)", borderRadius: "50%", padding: "1px" }}> */}
	// 			{urlFotoPersonal !== null ? (
	// 				// <Avatar
	// 				// 	src={urlFotoPersonal}
	// 				// 	style={{
	// 				// 		width: "70px",
	// 				// 		height: "85px",
	// 				// 		borderRadius: "50%",
	// 				// 	}}
	// 				// />
	// 				<Image src={urlFotoPersonal} width={80} style={{ borderRadius: "50%" }} preview={false} />
	// 			) : (
	// 				<Avatar size={80} icon={<UserOutlined />} />
	// 			)}
	// 			{/* </div> */}
	// 		</Tooltip>
	// 	</div>
	// );

	const BirthdayCard = ({ id, nombreYApellidoPaterno, fechaNacimiento, urlFotoPersonal }) => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{/* Imagen de perfil con fondo de color */}
			<Tooltip title={nombreYApellidoPaterno} key={id}>
				{/* <div style={{ backgroundColor: "rgb(144 137 137)", borderRadius: "50%", padding: "1px" }}> */}
				{urlFotoPersonal !== null ? (
					// <Avatar
					// 	src={urlFotoPersonal}
					// 	style={{
					// 		width: "70px",
					// 		height: "85px",
					// 		borderRadius: "50%",
					// 	}}
					// />
					<Image
						src={urlFotoPersonal}
						width={70}
						height={70}
						style={{ borderRadius: "50%" }}
						preview={false}
					/>
				) : (
					<Avatar size={70} icon={<UserOutlined />} />
				)}
				{/* </div> */}
			</Tooltip>
		</div>
	);

	const onClickVerMas = () => {
		history.push("/comunicaciones-cultura/cumpleanios");
	};
	return (
		<div style={{ padding: "0px" }}>
			<Card
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "black",
								fontWeight: "normal",
								fontSize: "14px",
							}}
						>
							{/* <FcPlanner size="1.5em" style={{ marginRight: "0.5em" }} /> */}
							<span>Hoy</span>
						</div>
					</div>
				}
				extra={
					<Button type="link" icon={<SelectOutlined />} onClick={onClickVerMas}>
						Más
					</Button>
				}
				style={{
					marginBottom: "0px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}
			>
				{/* Contenido principal */}
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						gap: "5px",
						width: "100%",
					}}
				>
					{cumpleanios.map((item, idx) => (
						<BirthdayCard key={idx} {...item} />
					))}
				</div>

				{/* Footer con flechas y mes actual */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "5px 10px",
						marginTop: "5px",
					}}
				>
					<button
						onClick={handlePrev}
						disabled={pagina === 0}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina === 0 ? "not-allowed" : "pointer",
						}}
					>
						<LeftOutlined style={{ fontSize: "20px", color: pagina === 0 ? "#ccc" : "#333" }} />
					</button>

					<span style={{ fontWeight: "normal", fontSize: "14px" }}>
						{moment().format("D [de] MMMM YYYY")}
					</span>

					<button
						onClick={handleNext}
						disabled={pagina >= totalPaginas - 1}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina >= totalPaginas - 1 ? "not-allowed" : "pointer",
						}}
					>
						<RightOutlined
							style={{ fontSize: "20px", color: pagina >= totalPaginas - 1 ? "#ccc" : "#333" }}
						/>
					</button>
				</div>
			</Card>
		</div>
	);
};

const Reconocimientos = () => {
	moment.locale("es");

	const [reconocimientos, setReconocimientos] = useState([]);
	const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);

	useEffect(() => {
		const getReconocimientos = async () => {
			const respuesta = await PublicacionService.listarReconocimientosPaginado({
				pagina: pagina,
				cantidad: 2,
			});
			console.log("reconocimientos::", respuesta);
			setReconocimientos(respuesta.reconocimientos);
			setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
		};

		getReconocimientos();
	}, [pagina]);

	const handleNext = () => {
		if (pagina < totalPaginas - 1) {
			setPagina((prev) => prev + 1);
		}
	};

	const handlePrev = () => {
		if (pagina > 0) {
			setPagina((prev) => prev - 1);
		}
	};

	const ReconocimientoCard = ({ id, colaborador, usuarioRegistro, titulo, descripcion }) => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start", // Alinea al inicio
				width: "120px", // Ajuste uniforme para todas las tarjetas
				padding: "5px",
				textAlign: "center",
				boxSizing: "border-box",
			}}
		>
			{/* Fecha */}
			<span style={{ fontSize: "12px", fontWeight: "normal", color: "black", minHeight: "20px" }}>
				{moment(usuarioRegistro.fechaRegistro).format("MMM").toUpperCase()}.
				{moment(usuarioRegistro.fechaRegistro).format("DD")}
			</span>

			{/* Imagen o Avatar */}
			<div
				style={{
					width: "70px",
					height: "70px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "50%",
					overflow: "hidden", // Asegura que las imágenes no sobresalgan
					backgroundColor: "#f0f0f0", // Fondo gris claro para imágenes faltantes
				}}
			>
				<Tooltip title={colaborador.nombreCompleto} key={`tooltip-image-${id}`}>
					{colaborador.urlFotoPersonal ? (
						<Image
							src={colaborador.urlFotoPersonal}
							width={70}
							height={70}
							style={{ objectFit: "contain" }} // Asegura que las imágenes se ajusten correctamente
							preview={false}
						/>
					) : (
						<Avatar size={70} icon={<UserOutlined />} />
					)}
				</Tooltip>
			</div>

			{/* Título */}
			<Tooltip title={titulo} key={`tooltip-title-${id}`} color={"#fadb14"}>
				<span
					style={{
						marginTop: "5px",
						fontSize: "12px",
						fontWeight: "bold",
						color: "#333",
						display: "block", // Asegura el comportamiento uniforme
						maxWidth: "100%", // Se ajusta al ancho del contenedor
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
				>
					<TrophyOutlined style={{ color: "#fadb14", marginRight: "3px" }} />
					{titulo}
				</span>
			</Tooltip>

			{/* Descripción */}
			<Tooltip
				title={<span style={{ fontStyle: "italic" }}>{descripcion}</span>}
				key={`tooltip-descripcion-${id}`}
				color={"#595959"}
			>
				<span
					style={{
						marginTop: "5px",
						fontSize: "12px",
						fontStyle: "italic", // Texto en cursiva
						color: "#333",
						display: "-webkit-box", // Necesario para line-clamp
						WebkitBoxOrient: "vertical", // Necesario para line-clamp
						WebkitLineClamp: 2, // Limitar a 2 líneas
						overflow: "hidden", // Ocultar el texto adicional
						textOverflow: "ellipsis", // Agregar "..." al final del texto recortado
						maxWidth: "100%", // Asegura que se ajuste al ancho del contenedor
					}}
				>
					{descripcion}
				</span>
			</Tooltip>
		</div>
	);

	return (
		<div style={{ padding: "0px" }}>
			<Card
				// extra={
				// 	<Button type="link" icon={<SelectOutlined />} onClick={onClickVerMas}>
				// 					Más
				// 	</Button>
				// }
				style={{
					marginBottom: "0px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}
			>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "5px",
						width: "100%",
					}}
				>
					{reconocimientos.map((item, idx) => (
						<ReconocimientoCard key={idx} {...item} />
					))}
				</div>

				{/* Footer con flechas y mes actual */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "5px 10px",
						marginTop: "2px",
					}}
				>
					<button
						onClick={handlePrev}
						disabled={pagina === 0}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina === 0 ? "not-allowed" : "pointer",
						}}
					>
						<LeftOutlined style={{ fontSize: "20px", color: pagina === 0 ? "#ccc" : "#333" }} />
					</button>

					<span style={{ fontWeight: "normal", fontSize: "14px" }}>
						{moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1)}
					</span>

					<button
						onClick={handleNext}
						disabled={pagina >= totalPaginas - 1}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina >= totalPaginas - 1 ? "not-allowed" : "pointer",
						}}
					>
						<RightOutlined
							style={{ fontSize: "20px", color: pagina >= totalPaginas - 1 ? "#ccc" : "#333" }}
						/>
					</button>
				</div>
			</Card>
		</div>
	);
};

const Comunicaciones = () => {
	moment.locale("es");
	const [publicacionesFijas, setPublicacionesFijas] = useAtom(publicacionesFijasAtom);
	const [currentIndex, setCurrentIndex] = useState(0);
	//const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const { publicaciones, totalPublicaciones, loadMorePublicaciones, handleHeartClick, handleLikeClick } =
		usePublicacion();

	useEffect(() => {
		const cargarinformacion = async () => {
			const response = await PublicacionService.listarPublicacionesFijas();
			console.log("listarPublicacionesFijas::", response);
			setPublicacionesFijas(response);
		};

		cargarinformacion();
	}, []);

	// const prevCard = () => {
	// 	setCurrentIndex((prev) => (prev === 0 ? publicaciones.length - 1 : prev - 1));
	// };

	// const nextCard = () => {
	// 	setCurrentIndex((prev) => (prev === publicaciones.length - 1 ? 0 : prev + 1));
	// };

	// const currentCard = publicaciones[currentIndex];

	if (publicaciones.length === 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No existe datos." />;
	}

	return (
		<>
			{publicacionesFijas && publicacionesFijas.length > 0 && (
				<div
					style={{
						position: "relative",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						//minHeight: "200px",
					}}
				>
					<PostCard post={publicacionesFijas[currentIndex]} />
					{/* Botón Anterior */}
					{publicacionesFijas.length > 1 && (
						<Button
							shape="circle"
							icon={<LeftOutlined />}
							size="large"
							onClick={() =>
								setCurrentIndex((prev) => (prev === 0 ? publicacionesFijas.length - 1 : prev - 1))
							}
							style={{
								position: "absolute",
								top: "50%",
								left: "10px", // Ajuste para separarlo del borde
								transform: "translateY(-50%)",
								backgroundColor: "rgba(255, 255, 255, 0.9)",
								border: "none",
								boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
								zIndex: 10, // Asegura que esté al frente
							}}
						/>
					)}
					{/* Botón Siguiente */}
					{publicacionesFijas.length > 1 && (
						<Button
							shape="circle"
							icon={<RightOutlined />}
							size="large"
							onClick={() =>
								setCurrentIndex((prev) => (prev === publicacionesFijas.length - 1 ? 0 : prev + 1))
							}
							style={{
								position: "absolute",
								top: "50%",
								right: "10px", // Ajuste para separarlo del borde
								transform: "translateY(-50%)",
								backgroundColor: "rgba(255, 255, 255, 0.9)",
								border: "none",
								boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
								zIndex: 10,
							}}
						/>
					)}
				</div>
			)}

			<InfiniteScroll
				dataLength={publicaciones.length}
				next={loadMorePublicaciones}
				hasMore={publicaciones.length < totalPublicaciones}
				loader={<h4>Loading...</h4>}
				scrollThreshold={0.9} // Activa la carga cuando se alcanza el 90% del scroll
				scrollableTarget={null} // Sin contenedor, usa el scroll del navegador
			>
				<List
					itemLayout="vertical"
					size="large"
					dataSource={publicaciones}
					renderItem={(item) => (
						<PublicacionItem
							item={item}
							handleHeartClick={handleHeartClick}
							handleLikeClick={handleLikeClick}
						/>
					)}
				/>
			</InfiniteScroll>
		</>
	);
};

const PostCard = ({ post }) => {
	console.log("post::", post);
	return (
		<Card
			style={{
				width: "100%",
				marginBottom: "14px",
				borderRadius: "8px",
				boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
				overflow: "hidden",
				borderLeft: `5px solid #1890ff`,
			}}
			bodyStyle={{ padding: "14px" }}
		>
			{/* Imagen con botones de navegación */}
			{/* Información de la empresa */}
			<div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
				<img
					src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
					alt="Logotipo de la empresa"
					style={{
						height: "32px",
						//width: "40px",
						//borderRadius: "50%",
						marginRight: "16px",
						//border: `2px solid #1890ff`,
					}}
				/>
				<div style={{ flex: 1 }}>
					<h4 style={{ margin: 0 }}>{post?.razonSocial}</h4>
					<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>
						{moment(post?.fechaPublicacion).locale("es").format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
					</p>
				</div>
				<Tag color="blue">{post?.etiqueta}</Tag>
			</div>

			{/* Mensaje de la publicación */}
			<div style={{ marginTop: "12px" }}>
				<style>
					{`
            .custom-react-quill .ql-container {
              border: none !important;
              background-color: transparent;
            }
          `}
				</style>
				<ReactQuill
					value={post?.mensajeHTML}
					readOnly={true}
					className="custom-react-quill"
					theme="bubble"
					modules={{ toolbar: false }}
					placeholder="Escribe algo..."
				/>
			</div>
			{post?.imagenes && post?.imagenes.length > 0 && (
				<div style={{ position: "relative" }}>
					<img
						src={post.imagenes[0]}
						//alt={`imagen-${currentImageIndex}`}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
							borderRadius: "8px",
							transition: "all 0.5s ease-in-out",
						}}
					/>
				</div>
			)}
		</Card>
	);
};
const PublicacionItem = ({ item, handleHeartClick, handleLikeClick }) => {
	return (
		<List.Item
			key={item.id}
			actions={[
				<Space>
					<Button
						type="text"
						icon={
							<HeartOutlined
								style={{
									color: item.heartedColaborador ? "red" : undefined,
									marginRight: 4,
								}}
							/>
						}
						onClick={() => handleHeartClick(item.id)}
					>
						{item.hearts}
					</Button>
					<Button
						type="text"
						icon={
							<LikeOutlined
								style={{
									color: item.likedColaborador ? "blue" : undefined,
									marginRight: 4,
								}}
							/>
						}
						onClick={() => handleLikeClick(item.id)}
					>
						{item.likes}
					</Button>
				</Space>,
			]}
			style={{
				marginBottom: "21px",
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				overflow: "hidden",
				border: "1px solid #f0f0f0",
				background: "white",
			}}
		>
			<PublicacionContenido item={item} />
		</List.Item>
	);
};

const PublicacionContenido = ({ item }) => {
	const [loading, setLoading] = useState(true);
	const [imageLoading, setImageLoading] = useState(
		new Array(item.imagenes.length).fill(true) // Estado para cada imagen
	);
	const [activeImageIndex, setActiveImageIndex] = useState(0);
	const [isCarouselVisible, setIsCarouselVisible] = useState(false);
	const carouselRef = useRef(null);

	useEffect(() => {
		setLoading(false);
	}, [item.imagenes]);

	const handleImageLoad = (index) => {
		setImageLoading((prev) => {
			const newState = [...prev];
			newState[index] = false; // Marcar la imagen como cargada
			return newState;
		});
	};

	const renderImageGrid = () => {
		const baseStyle = {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			borderRadius: "4px",
			cursor: "pointer",
		};

		return (
			<div
				style={{
					display: "grid",
					gridTemplateColumns: item.imagenes.length > 1 ? "1fr 1fr" : "1fr",
					gap: "8px",
				}}
			>
				{item.imagenes.map((img, index) => (
					<div key={index} style={{ position: "relative", minHeight: "150px" }}>
						{imageLoading[index] && (
							<div
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									zIndex: 1,
								}}
							>
								<Spin />
							</div>
						)}
						<img
							src={img}
							alt={`imagen-${index}`}
							style={{
								...baseStyle,
								display: imageLoading[index] ? "none" : "block", // Oculta la imagen hasta que se cargue
							}}
							onLoad={() => handleImageLoad(index)}
							onClick={(e) => {
								e.stopPropagation();
								setActiveImageIndex(index);
								setIsCarouselVisible(true);
							}}
						/>
					</div>
				))}
			</div>
		);
	};

	return (
		<div>
			<div style={{ display: "flex", alignItems: "flex-start" }}>
				<img
					src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
					alt="Logotipo de la empresa"
					style={{ height: "32px", marginRight: "16px" }}
				/>
				<div style={{ flex: 1 }}>
					<h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
					<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
						{moment(item.usuarioRegistro.fechaRegistro)
							.locale("es")
							.format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
					</p>
				</div>
				<Tag color={item.colorEtiqueta}>{item.etiqueta}</Tag>
			</div>

			<ReactQuill
				value={item.mensajeHTML}
				readOnly={true}
				className="custom-react-quill"
				style={{ backgroundColor: "transparent" }}
				theme="bubble"
				modules={{ toolbar: false }}
				placeholder="Escribe algo..."
			/>

			<div style={{ marginTop: "16px" }}>{loading ? <Spin /> : renderImageGrid()}</div>

			{isCarouselVisible && (
				<Modal
  open={isCarouselVisible}
  footer={null}
  onCancel={() => setIsCarouselVisible(false)}
  centered
  width="100vw"
  style={{ maxWidth: "100vw", height: "100vh", padding: 0 }}
  closeIcon={<span style={{ fontSize: "20px", fontWeight: "bold" }}>×</span>}
  bodyStyle={{ padding: 0, margin: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
>
  <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
    {/* Botón Izquierda */}
    <Button
      shape="circle"
      icon={<LeftOutlined />}
      size="large"
      onClick={() => carouselRef.current.prev()}
      style={{
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        border: "none",
        boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
        zIndex: 10,
      }}
    />

    {/* Botón Derecha */}
    <Button
      shape="circle"
      icon={<RightOutlined />}
      size="large"
      onClick={() => carouselRef.current.next()}
      style={{
        position: "absolute",
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        border: "none",
        boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
        zIndex: 10,
      }}
    />

    {/* Carrusel con Zoom de Ant Design */}
    <Carousel ref={carouselRef} initialSlide={activeImageIndex} dots={true} infinite={true} swipeToSlide={true}>
      <Image.PreviewGroup>
        {item.imagenes.map((img, index) => (
          <div key={index} style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Image
              src={img}
              alt={`carousel-img-${index}`}
              style={{ maxWidth: "100vw", maxHeight: "100vh", objectFit: "contain" }}
            />
          </div>
        ))}
      </Image.PreviewGroup>
    </Carousel>
  </div>
</Modal>
			)}
		</div>
	);
};
export default MiPortalMovil;

const Container = styled.div`
	width: 100%;
`;
const SeccionPortal = styled.div`
	width: 100%;
	display: grid;
	gap: 5px;
	padding: 15px;
	background: #f5f5f5;

	@media (max-width: 860px) {
		/* Styles for smaller screens */
		grid-template-columns: repeat(1, 1fr); /* Stack cards vertically */
	}
`;
const StyledMenuItem = styled(Menu.Item)`
	color: black; /* Color por defecto */
	font-weight: normal;
	font-size: 14px;
	transition: color 0.3s;

	&:hover {
		color: #1890ff; /* Azul en hover */
	}
`;
