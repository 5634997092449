import React from "react";
import styled from "@emotion/styled";
import { DatePicker, Space, Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { visibleModalProgramarVaccionesAtom } from "../../ProgramarVacacionesStore";
import { useAtom } from "jotai";
import { JefeInmediatoService } from "services/VacacionesService";
import { fechaSeleccionadaAtom, totalDiasAtom } from "../../ProgramarVacacionesStore";
import {
	DownloadOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import { notification } from "antd";

const Filtros = () => {
	const [fechaSeleccionada, setFechaSeleccionada] = useAtom(fechaSeleccionadaAtom);
	const [, setTotalDias] = useAtom(totalDiasAtom);
	const [visibleModalProgramarVacciones, setVisibleModallProgramarVacciones] = useAtom(visibleModalProgramarVaccionesAtom);
	
	const onChange = (date) => {
		setFechaSeleccionada(date);
		setTotalDias(date.daysInMonth());
	};

    const handleOnclickExportarSaldoReporteExcel= async()=>{     
		try {
			const respuesta= await JefeInmediatoService.exportarSaldoReporteExcel({                 
			});
			const filename = respuesta.headers.filename;
			saveAs(respuesta.data,filename);
		} catch (error) {
			notification.error({
				message:"No hay vacaciones para exportar",        
				description: error.response.data.mensaje,
			})
		}
	}

	return (
		<Container>
			<Space>
				{/* <Button
					type="primary" 
              		icon={<DownloadOutlined />}
              		onClick={handleOnclickExportarSaldoReporteExcel}>
						Exportar saldo
				</Button> */}
				<Button
					type="primary" 
              		icon={<CalendarOutlined />}
              		onClick={() => setVisibleModallProgramarVacciones(true)}>
						Programar
				</Button>
				<DatePicker
					allowClear={false}
					onChange={onChange}
					defaultValue={fechaSeleccionada}
					picker="month"
					format="MMMM - YYYY"
					placeholder="Fecha"
				/>
			</Space>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: end;
	padding: 10px 0px 30px;
`;

export default Filtros;
