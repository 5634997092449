import { BaseService } from "./BaseService";
import qs from "qs";

export const PortalColaboradorService = {
	async obtenerDocumento(empresaId, colaboradorId) {
		const respuesta = await BaseService.get(`/portal-colaborador/empresa/${empresaId}/documento/${colaboradorId}`);
		return respuesta.data;
	},

	async listarDocumentos(empresaId, colaboradorId) {
		const respuesta = await BaseService.get(`/documento/colaborador/${colaboradorId}/empresa/${empresaId}`);
		return respuesta.data;
	},

	async obtenerUrl(empresaId, detalleEnvioId, plataforma) {
		const respuesta = await BaseService.get(
			`/documento/${detalleEnvioId}/empresa/${empresaId}/plataforma/${plataforma}/url`
		);
		return respuesta.data;
	},

	async obtenerUrlFirmaHolografa() {
		const respuesta = await BaseService.get(`/portal-colaborador/obtener-url-firma-holografa`);
		return respuesta.data;
	},

	async obtenerUrlFoto() {
		const respuesta = await BaseService.get(`/portal-colaborador/obtener-url-foto`);
		return respuesta.data;
	},

	async obtenerDatosPerfil() {
		const respuesta = await BaseService.get(`/portal-colaborador/obtener-datos-perfil`);
		return respuesta.data;
	},

	async obtenerDatosFormulario(formularioId, detalleEnvioId) {
		const respuesta = await BaseService.get("/portal-colaborador/obtener-datos-formulario", {
			params: {
				formularioId: formularioId,
				detalleEnvioId: detalleEnvioId,
			},
		});
		return respuesta.data;
	},

	async obtenerSesionUsuarioActualizada(params) {
		console.log("invocando a la url de actualizar sesión");
		return BaseService.get("/portal-colaborador/sesion", {
			params: { ...params },
		});
	},

	async obtenerUrlDescargaMovil(empresaId, detalleEnvioId) {
		const respuesta = await BaseService.get(
			`/documento/${detalleEnvioId}/empresa/${empresaId}/url-descargar-movil`
		);
		return respuesta.data;
	},

	async firmar(empresaId, detalleEnvioId, clave, plataformaFirma, base64FirmaColaborador) {
		const respuesta = await BaseService.post("/documento/firmar", {
			empresaId: empresaId,
			detalleEnvioId: detalleEnvioId,
			clave: clave,
			plataformaFirma: plataformaFirma,
			base64FirmaColaborador: base64FirmaColaborador,
		});
		return respuesta.data;
	},

	async listarFormularios(empresaId) {
		const respuesta = await BaseService.get(`/portal-colaborador/formulario/empresa/${empresaId}`);
		return respuesta.data;
	},

	async buscarFormulario(formularioId, empresaId) {
		const respuesta = await BaseService.get(`/portal-colaborador/formulario/${formularioId}/empresa/${empresaId}`);
		return respuesta.data;
	},

	async completarFormulario(documento) {
		const respuesta = await BaseService.post("/portal-colaborador/formulario/completar", documento);
		return respuesta.data;
	},

	async firmarFlujoFirma(documento) {
		const respuesta = await BaseService.post("/portal-colaborador/formulario/firmar-flujo-firma", documento);
		return respuesta.data;
	},

	async omitirDocumento(data) {
		const respuesta = await BaseService.post("/portal-colaborador/documento/omitir", data);
		return respuesta.data;
	},

	async obtenerInformacionFormulario(detalleEnvioId, empresaId) {
		const respuesta = await BaseService.get(
			`portal-colaborador/documento/${detalleEnvioId}/empresa/${empresaId}/formulario/informacion`
		);
		return respuesta.data;
	},
	async consultarRRHH(data) {
		const respuesta = await BaseService.post("/portal-colaborador/consultarRRHH", data);
		return respuesta.data;
	},
	async listaConsultas(empresaId, numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("portal-colaborador/listarConsultas", {
			empresaId: empresaId,
			numeroDocumentoIdentidad: numeroDocumentoIdentidad,
		});
		return respuesta.data;
	},
	async listaDetalleConsultas(consultaColaboradorId) {
		const respuesta = await BaseService.get("portal-colaborador/listarDetalleConsultas", {
			params: {
				consultaColaboradorId: consultaColaboradorId,
			},
		});
		return respuesta.data;
	},
	async listaNumeroRespuestas(consultasColaboradorIds, tipoDetalle) {
		const respuesta = await BaseService.get("portal-colaborador/numeroDetalleRespuestasNoVisualizadas", {
			params: {
				consultasColaboradorIds: consultasColaboradorIds,
				tipoDetalle: tipoDetalle,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},
	async cambiarEstadoVisualizacionDetalleConsulta(consultaColaborador) {
		const respuesta = await BaseService.post(
			"portal-colaborador/cambiarEstadoVisualizacionDetalleConsulta",
			consultaColaborador
		);
		return respuesta.data;
	},
	async solicitarVacaciones(vacacionesData) {
		const respuesta = await BaseService.post("portal-colaborador/SolicitarVacaciones", vacacionesData);
		return respuesta.data;
	},
	async obtenerTipoAbsentismo() {
		const respuesta = await BaseService.get("portal-colaborador/tipoAbsentismo");
		return respuesta.data;
	},
	async obtenerJefeInmediato() {
		const respuesta = await BaseService.get("portal-colaborador/obtenerJefeInmediato");
		return respuesta.data;
	},
	async listarVacaciones(numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("portal-colaborador/listarVacaciones", {
			params: {
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
			},
		});
		return respuesta.data;
	},
	async cargarDocumentos(datos) {
		const respuesta = await BaseService.post("portal-colaborador/cargarDocumentos", datos);
		return respuesta.data;
	},

	async generarPDFArchivosSubidos(datos) {
		const respuesta = await BaseService.post("portal-colaborador/generarPDFArchivosSubidos", datos);
		return respuesta.data;
	},
	async obtenerFormatoCarga(formularioId) {
		const respuesta = await BaseService.get(`portal-colaborador/obtenerUrlFormatoCarga/${formularioId}`);
		return respuesta.data;
	},
	async buscarColaborador() {
		const respuesta = await BaseService.get("portal-colaborador/buscar-colaborador");
		return respuesta.data;
	},
	async listarPorNombreCompletoODni(data) {
		const respuesta = await BaseService.get("/colaborador/listar-por-nombre-o-dni", {
			params: {
				...data,
			},
		});
		return respuesta.data;
	},

	async buscarOrganigrama(empresaId, numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("portal-colaborador/buscar-organigrama", {
			empresaId: empresaId,
			numeroDocumentoIdentidad: numeroDocumentoIdentidad,
		});
		return respuesta.data;
	},

	async obtenerUrlSolicitudDocumentoPersonal(solicitudId) {
		const respuesta = await BaseService.get(
			`portal-colaborador/obtenerUrlSolicitudDocumentoPersonal/${solicitudId}`
		);
		return respuesta.data;
	},

	async obtenerUrlSolicitudDocumentoPersonalMovil(solicitudId) {
		const respuesta = await BaseService.get(
			`portal-colaborador/obtenerUrlSolicitudDocumentoPersonalMovil/${solicitudId}`
		);
		return respuesta.data;
	},

	async listarDocumentosPendientes(empresaId, colaboradorId) {
		const respuesta = await BaseService.get(
			`/documento/pendientes/colaborador/${colaboradorId}/empresa/${empresaId}`
		);
		return respuesta.data;
	},
	async actualizarDomicilio(colaborador) {
		const respuesta = await BaseService.post("portal-colaborador/actualizar-domicilio", colaborador);
		return respuesta.data;
	},
	async actualizarDatosNacimiento(colaborador) {
		const respuesta = await BaseService.post("portal-colaborador/actualizar-datos-nacimiento", colaborador);
		return respuesta.data;
	},

	async actualizarContactoEmergencia(colaborador) {
		const respuesta = await BaseService.post("portal-colaborador/actualizar-contacto-emergencia", colaborador);
		return respuesta.data;
	},
	async agregarDeclaracionYobtenerUltimoDocumento(datos) {
		const respuesta = await BaseService.post("portal-colaborador/agregarDeclaracionYobtenerUltimoDocumento", datos);
		return respuesta.data;
	},
	async validarExisteClave() {
		const respuesta = await BaseService.get(`/portal-colaborador/validarExisteClave`);
		return respuesta.data;
	},
	async obtenerNumeroPublicaciones() {
		const respuesta = await BaseService.get("portal-colaborador/obtener-numero-publicaciones");
		return respuesta.data;
	},
};
