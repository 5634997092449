import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useAtom } from "jotai";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles
import {
	Badge,
	Carousel,
	Breadcrumb,
	Input,
	Typography,
	Descriptions,
	Avatar,
	List,
	Skeleton,
	Button,
	Tag,
	Space,
	Empty,
	Row,
	Col,
	Card,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import LoginService from "services/LoginService";
import { PublicacionItem } from "./componentes/PublicacionItem"; // Componente individual
import { PublicacionService } from "services/comunicacion-cultura";
import { usePublicacion } from "../../hooks";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { publicacionesFijasAtom } from "../../store/PublicacionStore";
import moment from "moment";
import "moment/locale/es";
const { Text } = Typography;

moment.locale("es");
const ListaPublicaciones = () => {
	const [publicacionesFijas, setPublicacionesFijas] = useAtom(publicacionesFijasAtom);
	const [currentIndex, setCurrentIndex] = useState(0);
	//const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const { publicaciones, totalPublicaciones, loadMorePublicaciones, handleHeartClick, handleLikeClick } =
		usePublicacion();

	useEffect(() => {
		const cargarinformacion = async () => {
			const response = await PublicacionService.listarPublicacionesFijas();
			console.log("listarPublicacionesFijas::", response);
			setPublicacionesFijas(response);
		};

		cargarinformacion();
	}, []);

	// const prevCard = () => {
	// 	setCurrentIndex((prev) => (prev === 0 ? publicaciones.length - 1 : prev - 1));
	// };

	// const nextCard = () => {
	// 	setCurrentIndex((prev) => (prev === publicaciones.length - 1 ? 0 : prev + 1));
	// };

	// const currentCard = publicaciones[currentIndex];

	if (publicaciones.length === 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No existe datos." />;
	}

	return (
		<>
			{publicacionesFijas && publicacionesFijas.length > 0 && (
				<div
					style={{
						position: "relative",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						//minHeight: "200px",
					}}
				>
					<PostCard post={publicacionesFijas[currentIndex]} />
					{/* Botón Anterior */}
					{publicacionesFijas.length > 1 && (
						<Button
							shape="circle"
							icon={<LeftOutlined />}
							size="large"
							onClick={() =>
								setCurrentIndex((prev) => (prev === 0 ? publicacionesFijas.length - 1 : prev - 1))
							}
							style={{
								position: "absolute",
								top: "50%",
								left: "10px", // Ajuste para separarlo del borde
								transform: "translateY(-50%)",
								backgroundColor: "rgba(255, 255, 255, 0.9)",
								border: "none",
								boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
								zIndex: 10, // Asegura que esté al frente
							}}
						/>
					)}
					{/* Botón Siguiente */}
					{publicacionesFijas.length > 1 && (
						<Button
							shape="circle"
							icon={<RightOutlined />}
							size="large"
							onClick={() =>
								setCurrentIndex((prev) => (prev === publicacionesFijas.length - 1 ? 0 : prev + 1))
							}
							style={{
								position: "absolute",
								top: "50%",
								right: "10px", // Ajuste para separarlo del borde
								transform: "translateY(-50%)",
								backgroundColor: "rgba(255, 255, 255, 0.9)",
								border: "none",
								boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
								zIndex: 10,
							}}
						/>
					)}
				</div>
			)}

			<InfiniteScroll
				dataLength={publicaciones.length}
				next={loadMorePublicaciones}
				hasMore={publicaciones.length < totalPublicaciones}
				loader={<h4>Loading...</h4>}
				scrollThreshold={0.9} // Activa la carga cuando se alcanza el 90% del scroll
				scrollableTarget={null} // Sin contenedor, usa el scroll del navegador
			>
				<List
					itemLayout="vertical"
					size="large"
					dataSource={publicaciones}
					renderItem={(item) => (
						<PublicacionItem
							item={item}
							handleHeartClick={handleHeartClick}
							handleLikeClick={handleLikeClick}
						/>
					)}
				/>
			</InfiniteScroll>
		</>
	);
};
const PostCard = ({ post }) => {
	console.log("post::", post);
	return (
		<Card
			style={{
				width: "100%",
				marginBottom: "16px",
				borderRadius: "8px",
				boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
				overflow: "hidden",
				borderLeft: `5px solid #1890ff`,
			}}
			bodyStyle={{ padding: "16px" }}
		>
			{/* Imagen con botones de navegación */}
			{/* Información de la empresa */}
			<div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
				<img
					src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
					alt="Logotipo de la empresa"
					style={{
						height: "32px",
						//width: "40px",
						//borderRadius: "50%",
						marginRight: "16px",
						//border: `2px solid #1890ff`,
					}}
				/>
				<div style={{ flex: 1 }}>
					<h4 style={{ margin: 0 }}>{post?.razonSocial}</h4>
					<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>
						{moment(post?.fechaPublicacion).locale("es").format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}
					</p>
				</div>
				<Tag color="blue">{post?.etiqueta}</Tag>
			</div>

			{/* Mensaje de la publicación */}
			<div style={{ marginTop: "12px" }}>
				<style>
					{`
            .custom-react-quill .ql-container {
              border: none !important;
              background-color: transparent;
            }
          `}
				</style>
				<ReactQuill
					value={post?.mensajeHTML}
					readOnly={true}
					className="custom-react-quill"
					theme="bubble"
					modules={{ toolbar: false }}
					placeholder="Escribe algo..."
				/>
			</div>
			{post?.imagenes && post?.imagenes.length > 0 && (
				<div style={{ position: "relative" }}>
					<img
						src={post.imagenes[0]}
						//alt={`imagen-${currentImageIndex}`}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
							borderRadius: "8px",
							transition: "all 0.5s ease-in-out",
						}}
					/>
				</div>
			)}
		</Card>
	);
};
export default ListaPublicaciones;
