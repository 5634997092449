import React, { useState, useEffect } from "react";
import { Card, Avatar, Button, Image, Tooltip, List, Skeleton, Empty } from "antd";
import { RightOutlined, LeftOutlined, UserOutlined, SearchOutlined, SelectOutlined } from "@ant-design/icons";
import { Container } from "components/Container";
import { FcPlanner } from "react-icons/fc";
import moment from "moment";
import { PublicacionService } from "services/comunicacion-cultura";
import InfiniteScroll from "react-infinite-scroll-component";
import useCumpleanios from "../../../hooks/useCumpleanios";
import { useHistory } from "react-router-dom";

const ListarCumpleaniosMovil = () => {
	moment.locale("es");
	const history = useHistory();
	// const [cumpleanios, setCumpleanios] = useState([]);
	// const [pagina, setPagina] = useState(0);
	// const [totalPaginas, setTotalPaginas] = useState(0);

	// useEffect(() => {
	// 	const getCumpleanios = async () => {
	// 		const respuesta = await PublicacionService.listarCumpleaniosPaginado({
	// 			pagina: pagina,
	// 			cantidad: 3,
	// 		});
	// 		console.log("Cumpleanios::", respuesta);
	// 		setCumpleanios(respuesta.cumpleanios);
	// 		setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
	// 	};

	// 	getCumpleanios();
	// }, [pagina]);

	const { cumpleanios, totalCumpleanios, loadMoreCumpleanios } = useCumpleanios();

	if (cumpleanios == null  || cumpleanios.length === 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No existe datos." />;
	}

	const onClickRetonar = () => {
		history.push("/v3/mi-portal");
	};

	return (
		<div
			style={{
				marginTop: "20px", // Centra horizontalmente
				width: "100%", // Ajusta el ancho al 60% del contenedor principal
				padding: "0 16px", // Padding lateral para asegurar espaciado adicional
			}}
		>
			<Container
				titulo={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								// color: "black",
								// fontWeight: "bold",
								fontSize: "14px",
							}}
						>
							<FcPlanner size="1.5em" style={{ marginRight: "0.5em" }} />
							<span>Cumpleaños de {moment().format("MMMM YYYY").charAt(0).toUpperCase() + moment().format("MMMM YYYY").slice(1)}</span>
						</div>
					</div>
				}
				 onClickRetornar={onClickRetonar}
			>
				<InfiniteScroll
					dataLength={cumpleanios.length}
					next={loadMoreCumpleanios}
					hasMore={cumpleanios.length < totalCumpleanios}
					loader={<h4>Loading...</h4>}
					scrollThreshold={0.9} // Activa la carga cuando se alcanza el 90% del scroll
					scrollableTarget={null} // Sin contenedor, usa el scroll del navegador
				>
					<List
						dataSource={cumpleanios}
						renderItem={(item) => (
							<List.Item key={item.id}>
								<List.Item.Meta
									avatar={
										item.urlFotoPersonal !== null ? (
											<Avatar
												src={item.urlFotoPersonal}
												style={{
													width: "70px",
													height: "85px",
													borderRadius: "50%",
												}}
											/>
										) : (
											<Avatar size={80} icon={<UserOutlined />} />
										)
									}
									title={item.nombreCompleto}
									description={`${moment(item.fechaNacimiento).locale("es").format("D [de] MMMM")}`}
								/>
								{/* <div>Content</div> */}
							</List.Item>
						)}
					/>
					{/* Espaciador al final */}
					<div
						style={{
							height: "80px", // Altura fija para simular el espacio al final
						}}
					/>
				</InfiniteScroll>
			</Container>
		</div>
	);
};

export default ListarCumpleaniosMovil;
