import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.BASE;

const BeneficioExternoService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/comunicaciones-cultura/beneficiosExternos");
		return respuesta.data;
	},
};
export default BeneficioExternoService;
