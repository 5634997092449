import React, { useState, useEffect } from "react";
import { Card, Avatar, Button, Image, Tooltip, Menu } from "antd";
import { RightOutlined, LeftOutlined, UserOutlined, TrophyOutlined } from "@ant-design/icons";
import { FaGift } from "react-icons/fa";
import { FcReading } from "react-icons/fc";
import { FaRegSmile } from "react-icons/fa";
import moment from "moment";
import styled from "@emotion/styled";
import { BeneficioExternoService } from "services/comunicacion-cultura";

const ListarBeneficiosExternos = () => {
	moment.locale("es");

	const [beneficiosExternos, setBeneficiosExternos] = useState([]);

	useEffect(() => {
		const getBeneficiosExternos = async () => {
			const respuesta = await BeneficioExternoService.listar();
			setBeneficiosExternos(respuesta);
		};

		getBeneficiosExternos();
	}, []);

	return (
		<div style={{ padding: "0px" }}>
			<Card
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "black",
								fontWeight: "normal",
								fontSize: "14px",
							}}
						>
							<FcReading size="1.5em" style={{ marginRight: "0.5em" }} />
							<span>Mis beneficios Externos</span>
						</div>
					</div>
				}
				style={{
					marginBottom: "16px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}
				bodyStyle={{
					padding: "0", // Sin padding dentro del Card
				}}
			>
				<Menu
					mode="vertical"
					onClick={(e) => {
						const beneficio = beneficiosExternos.find((b) => b.id.toString() === e.key);
						if (beneficio && beneficio.url) {
							window.open(beneficio.url, "_blank");
						}
					}}
					style={{
						height: "100%", // Ocupa todo el Card
						border: "none",
					}}
				>
					{beneficiosExternos.map((beneficio) => (
						<StyledMenuItem
							key={beneficio.id}
							//   style={{
							//     color: "#333", // Letra negra por defecto
							//     fontWeight: "500",
							//     transition: "color 0.3s, background-color 0.3s", // Transición para el hover
							//   }}
						>
							{beneficio?.logo && (
								<img
									src={beneficio.logo}
									alt="Logo Beneficio"
									style={{
										// height: "35x",
										width: "35px", // 🔹 Hacer que sea cuadrada antes de aplicar border-radius
										borderRadius: "25%", // 🔹 Hace la imagen circular
										marginRight: "16px",
										objectFit: "cover", // 🔹 Evita deformaciones si la imagen no es cuadrada
										border: "2px solid #ddd", // 🔹 (Opcional) Agrega un borde suave
									}}
								/>
							)}

							{beneficio.nombre}
						</StyledMenuItem>
					))}
				</Menu>
			</Card>
		</div>
	);
};

export default ListarBeneficiosExternos;

const StyledMenuItem = styled(Menu.Item)`
	color: black; /* Color por defecto */
	font-weight: normal;
	font-size: 14px;
	transition: color 0.3s;

	&:hover {
		color: #1890ff; /* Azul en hover */
	}
`;
