import React, { useEffect, useContext, useState } from "react";
import { Button, Modal, Alert, Input, message, Divider, Select, DatePicker, Form } from "antd";
import { useAtom } from "jotai";
import { visibleModalProgramarVaccionesAtom, refetchColaboradoresAtom } from "../../ProgramarVacacionesStore";
import { JefeInmediatoService } from "services/VacacionesService";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";
import { useModalVisorPdf } from "store/global";
import { useListarTipoAusencia} from "../../hooks";
import ColaboradorService from "services/ColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { ApiResponse } from "enum";
import moment from "moment";

const ModalProgramarVacaciones = () => {
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { tiposAusencia } = useListarTipoAusencia();
    const [colaboradores, setColaboradores] = useState(undefined);
    const [visibleModalProgramarVacaciones, setVisibleModalProgramarVacaciones] = useAtom(visibleModalProgramarVaccionesAtom);
    const [refetchColaboradores, setRefetchColaboradores] = useAtom(refetchColaboradoresAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const onSearchColaboradores = async (value) => {
        if (value === "") {
            setColaboradores([]);
            return;
        }
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim(),
        });
        setColaboradores(respuesta.colaboradores);
    };

    const onClickCerrar = () => {
        form.resetFields();
        setVisibleModalProgramarVacaciones(false);
    };

    const onClickBtnProgramaVacaciones = async (_formulario) => {
        _formulario.numeroDocumentoAdmin = getUsuarioAutenticado().numeroDocumentoIdentidad;
        _formulario.organizacionId = getUsuarioAutenticado().organizacionId;

        const formData = new FormData();
        formData.append(
            "info",
            new Blob(
                [
                    JSON.stringify({
                        tipoAusenciaId: _formulario.tipoAusenciaId,
                        numeroDocumentoColaborador: _formulario.colaborador,
                        fechaInicio: _formulario.fechasSolicitud[0].format("DD/MM/YYYY HH:mm:ss"),
                        fechaFin: _formulario.fechasSolicitud[1].format("DD/MM/YYYY HH:mm:ss"),
                        comentario: _formulario.comentario,
                        numeroDocumentoJefeInmediato: _formulario.numeroDocumentoAdmin,
                        organizacionId: _formulario.organizacionId,
                    }),
                ],
                {
                    type: "application/json",
                }
            )
        );

        const response = await JefeInmediatoService.programarVacacionesFromColaborador(formData);

        if (response.estado === "EXITO") {
            form.resetFields();
            setVisibleModalProgramarVacaciones(false);
            message.success("Se ha procesado correctamente su programación de vacaciones");
            // 🔹 Incrementar el contador para forzar la recarga en Calendario
            setRefetchColaboradores((prev) => prev + 1); 
        } else {
            message.error(response.mensaje);
        }
    };

    const deshabilitarFechaSolicitudInicio = (current) => {
        return current && current < moment().startOf("day");
    };

   const onOpenModal = () => {
        form.resetFields(); // 🔹 Resetea el formulario antes de asignar valores
        if (tiposAusencia.length > 0) {
            const tipoVacaciones = tiposAusencia.find((tipo) => tipo.descripcion === "VACACIONES");
            if (tipoVacaciones) {
                form.setFieldsValue({ tipoAusenciaId: tipoVacaciones.id });
            }
        }
    };

    useEffect(() => {
        if (visibleModalProgramarVacaciones) {
            onOpenModal();
        }
    }, [visibleModalProgramarVacaciones, tiposAusencia]);


    return (
        <Modal
            title="Programar Vacaciones"
            open={visibleModalProgramarVacaciones}
            width={500}
            onCancel={onClickCerrar}
            destroyOnClose={true}
            centered
            onOk={() => form.submit()}
            okText={"Programar"}
            maskClosable={false}
            afterOpenChange={(isOpen) => isOpen && onOpenModal()} // 🔹 Ejecutar cada vez que se abre
        >
            <Form form={form} layout={"vertical"} onFinish={onClickBtnProgramaVacaciones} style={{ padding: "0px 5%" }}>
                <Form.Item
                    label="Tipo Ausencia"
                    name={"tipoAusenciaId"}
                    labelAlign="left"
                    rules={[{ required: true, message: "Seleccione el tipo" }]}
                >
                    <Select style={{ width: "100%" }} disabled>
                        {tiposAusencia !== undefined &&
                            tiposAusencia.map((tipo) => {
                                return (
                                    <Select.Option key={tipo.id} value={tipo.id}>
                                        {tipo.descripcion}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Colaborador"
                    name="colaborador"
                    rules={[{ required: true, message: "Ingrese al colaborador" }]}
                >
                    <Select
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del colaborador"
                        onSearch={onSearchColaboradores}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        //onSelect={onSelectColaborador}
                        //onChange={onChangeColaborador}
                    >
                        {colaboradores !== undefined &&
                            colaboradores?.map((colaborador) => (
                                <Select.Option
                                    key={colaborador.numeroDocumentoIdentidad}
                                    value={colaborador.numeroDocumentoIdentidad}
                                >
                                    {colaborador.nombreCompleto}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <div>
                            <CalendarOutlined /> Fechas Solicitadas
                        </div>
                    }
                    name="fechasSolicitud"
                    labelAlign="left"
                    rules={[{ required: true, message: "Ingrese la la fecha" }]}
                    //extra={<FechasSolicitadasExtra />}
                >
                    <RangePicker
                        allowClear={true}
                        // format={tipoAusencia?.contabilizarHoras ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY"}
                        format="DD/MM/YYYY"
                        //showTime={tipoAusencia?.contabilizarHoras ? true : false}
                        //disabledDate={tipoAusencia?.permitirSolicitudFechasAnteriores ?false: deshabilitarFechaSolicitudInicio}
                        // disabledDate={deshabilitarFechaSolicitudInicio}
                        // onChange={(fechasSolicitadas) => {
                        // 	descontarDias(fechasSolicitadas);
                        // }}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="Observación" name="comentario" labelAlign="left">
                    <TextArea autoSize={{ minRows: 2, maxRows: 5 }} showCount maxLength={250} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalProgramarVacaciones;
